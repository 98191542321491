import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid, MenuItem, Select, FormControl, InputLabel, TextareaAutosize, Snackbar, Alert, IconButton } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AssignmentNavbar from './AssignmentNavbar';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT, Urls } from '../Network/Url';
import toast, { Toaster } from 'react-hot-toast';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddAssignment = () => {

    const navigate = useNavigate();

    const [hardDeadline, setHardDeadline] = useState(null);
    const [softDeadline, setSoftDeadline] = useState(null);
    const [scheduleOn, setScheduleOn] = useState(null);
    const [bdeOptions, setBdeOptions] = useState([]);
    const [expertOptions, setExpertOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);  // Initialized as an array
    const [expertManagerOptions, setExpertManagerOptions] = useState([]);
    const [projectAssignerOptions, setProjectAssignerOptions] = useState([]);
    const [qualityOptions, setQualityOptions] = useState([]);

    const [title, setTitle] = useState('');
    const [details, setDetails] = useState('');
    const [clientId, setClientId] = useState('');
    const [bdeId, setBdeId] = useState('');
    const [expertManagerId, setExpertManagerId] = useState('');
    const [subject, setSubject] = useState('');
    const [budget, setBudget] = useState('');
    const [numberOfWords, setNumberOfWords] = useState('');
    const [files, setFiles] = useState([]);
    const [fileData, setFileData] = useState([]);
    

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleBudgetChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setBudget(value);
    };

    const handleChange = (value) => {
        setDetails(value);
    };

    const handleNumberOfWordsChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setNumberOfWords(value);
    };

    const handleFileChange = async (e) => {
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);

        const token = localStorage.getItem("authToken");

        for (const file of newFiles) {
            const formdata = new FormData();
            formdata.append("file", file);

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    "authorization": `Bearer ${token}`,
                }),
                body: formdata,
                redirect: 'follow'
            };

            try {
                const response = await fetch(`${ENDPOINT}/${Urls.uploadFile}`, requestOptions);
                const result = await response.json();
                if (result.Success === 1) {
                    setFileData((prevData) => [...prevData, { url: result.Data.url, name: file.name }]);
                } else {
                    console.error('Failed to upload file:', result.Message);
                    toast.error(result.Message);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setFileData((prevData) => prevData.filter((_, i) => i !== index)); // Remove URL and name as well
    };

    const token = localStorage.getItem("authToken");
    const userData = JSON.parse(localStorage.getItem('userData')) || {};

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${ENDPOINT}/${Urls.assignmentUser}`, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.Success) {
                    const combinedOptions = [
                        ...result.Data.bde,
                        ...result.Data['expert-manager'],
                        ...result.Data['project-assigner']
                    ];
                    setBdeOptions(combinedOptions);
                    setExpertOptions(result.Data.expert);
                    setClientOptions(result.Data.client);
                    setExpertManagerOptions(result.Data['expert-manager']);
                    setProjectAssignerOptions(result.Data['project-assigner']);
                    setQualityOptions(result.Data.quality);
                    setSubjectOptions(Array.isArray(result.Data.subjects) ? result.Data.subjects : []);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSubmit = async () => {
        if (!title) {
            toast.error('Title is required.');
            return;
        }

        const payload = {
            ...(title && { title }),
            ...(details && { details }),
            ...(clientId && { client_id: clientId }),
            ...(bdeId && { bde_id: bdeId }),
            ...(expertManagerId && { expert_manager_id: expertManagerId }),
            ...(subject && { subject }),
            ...(hardDeadline && { hard_deadline: hardDeadline.toISOString() }),
            ...(softDeadline && { soft_deadline: softDeadline.toISOString() }),
            ...(scheduleOn && { schedule_on: scheduleOn.toISOString() }),
            ...(budget && { budget: parseFloat(budget) }),
            ...(numberOfWords && { number_of_words: parseInt(numberOfWords, 10) }),
            ...(fileData.length > 0 && { docs: fileData }),
        };

        try {
            const response = await fetch(`${ENDPOINT}/${Urls.addAssignment}`, {
                method: 'POST',
                headers: {
                    'authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();
            if (result.Success) {
                setSnackbarMessage('Created Successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                console.log('Assignment ID:', result.Data.assignmentId);
                setTimeout(() => {
                    navigate('/all-assignment');
                }, 3000);
            } else {
                setSnackbarMessage('Failed to create assignment');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error creating assignment:', error);
            setSnackbarMessage('Error creating assignment');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <AssignmentNavbar />
            <Toaster />
            <Box p={3} sx={{ background: '#E8E8E8' }}>
                <Typography variant="h4" component="div" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', fontFamily: 'CustomFont', mb: 3, fontSize: '22px', fontWeight: '700' }}>
                    Add New Assignment
                </Typography>
                <Grid container spacing={3} sx={{ background: '#FFFFFF', width: '100%', padding: '30px', marginLeft: 0, marginTop: '10px' }}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Title"
                            variant="outlined"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'left' }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="client-label">Client</InputLabel>
                            <Select
                                labelId="client-label"
                                label="Client"
                                value={clientId}
                                onChange={(e) => setClientId(e.target.value)}
                            >
                                {clientOptions.map(client => (
                                    <MenuItem key={client.userId} value={client.userId}>{client.name} ({client.userId})</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'left' }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="bde-label">BDE</InputLabel>
                            <Select
                                labelId="bde-label"
                                label="BDE"
                                value={bdeId}
                                onChange={(e) => setBdeId(e.target.value)}
                            >
                                {bdeOptions.map(bde => (
                                    <MenuItem key={bde.userId} value={bde.userId}>{bde.name} ({bde.userId})</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'left' }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="expert-manager-label">Expert Manager</InputLabel>
                            <Select
                                labelId="expert-manager-label"
                                label="Expert Manager"
                                value={expertManagerId}
                                onChange={(e) => setExpertManagerId(e.target.value)}
                            >
                                {expertManagerOptions.map(manager => (
                                    <MenuItem key={manager.userId} value={manager.userId}>{manager.name} ({manager.userId})</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'left' }}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="subject-list-label">Subject List</InputLabel>
                            <Select
                                labelId="subject-list-label"
                                label="Subject List"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            >
                                {subjectOptions.map(subject => (
                                    <MenuItem key={subject} value={subject}>{subject}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            selected={hardDeadline}
                            onChange={(date) => setHardDeadline(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Hard Deadline"
                            customInput={<TextField label="Hard Deadline" fullWidth variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker
                            selected={softDeadline}
                            onChange={(date) => setSoftDeadline(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Soft Deadline"
                            customInput={<TextField label="Soft Deadline" fullWidth variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker
                            selected={scheduleOn}
                            onChange={(date) => setScheduleOn(date)}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Schedule On"
                            customInput={<TextField label="Schedule On" fullWidth variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Budget"
                            variant="outlined"
                            value={budget}
                            onChange={handleBudgetChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Number of Words"
                            variant="outlined"
                            value={numberOfWords}
                            onChange={handleNumberOfWordsChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReactQuill
                            theme="snow"
                            value={details}
                            onChange={handleChange}
                            placeholder="Write assignment details here..."
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, false] }],
                                    ['bold', 'italic', 'underline', 'strike'], // Formatting buttons
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'], // Link and image buttons
                                    ['clean'] // Clear formatting
                                ]
                            }}
                            style={{
                                height: '200px',
                                marginBottom: '20px',
                                fontFamily: 'Arial',
                                fontSize: '16px',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            variant="outlined"
                            component="label"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '150px',
                                height: '150px',
                                background: '#E0E0E0',
                                color: 'black',
                                fontFamily: 'CustomFont',
                                fontSize: '16px',
                                borderRadius: '8px',
                                flexDirection: 'column',
                                border: '1px dashed #3C455E',
                                mt: 2
                            }}
                        >
                            <AddIcon sx={{ fontSize: '48px' }} />
                            <Typography>Upload Docs</Typography>
                            <input type="file" hidden multiple onChange={handleFileChange} />
                        </Button>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        {files.map((file, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                                <Typography variant="body1">{file.name}</Typography>
                                <IconButton aria-label="delete" onClick={() => handleRemoveFile(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                    </Grid>

                    <Grid item xs={12} textAlign={'-webkit-center'}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ display: 'block', width: '16%', padding: '10px', background: '#3C455E', color: 'white', fontFamily: 'CustomFont' }}
                            onClick={handleSubmit}
                        >
                            Create
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
};

export default AddAssignment;
