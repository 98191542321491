// src/Login.js
import React from 'react';
import './Login.css'; // Create and import the CSS file for styling
import Logo from '../Components/Assets/images/zomakLogo.svg'
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ENDPOINT, Urls } from '../Network/Url';

function Login() {

  const navigate = useNavigate();

  // const handleLogin = (event) => {
  //     event.preventDefault();
  //     // Here you can add your authentication logic
  //     navigate('/dashboard');
  //   };

  const handleLogin = async (event) => {
    event.preventDefault();

    const emailOrUserId = event.target.elements['outlined-email'].value;
    const password = event.target.elements['outlined-password'].value;

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      emailOrUserId: emailOrUserId,
      password: password
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    try {
      const response = await fetch(`${ENDPOINT}/${Urls.login}`, requestOptions);
      const result = await response.json();
      if (result.Success === 1) {
        localStorage.setItem('authToken', result.Data.token);
        localStorage.setItem('userData', JSON.stringify(result.Data.user));
        toast.success(result.Message);
        if (result.Data.user.usertype === 7) {
          navigate('/c-dashboard');
        } else {
          navigate('/dashboard');
        }
      } else {
        toast.error(result.message || 'Login failed');
      }
    } catch (error) {
      toast.error('An error occurred during login');
      console.error('error', error);
    }
  };




  return (
    <div className="login-container">
      <div className="login-box">
        <img src={Logo} alt="Zomak Logo" className="logo" />
        <h2 style={{ fontFamily: 'CustomFont', fontSize: '25px', fontWeight: '700', letterSpacing: '0.5px', lineHeight: '24px' }}>Login</h2>
        <form style={{ display: 'grid', justifyItems: 'center' }} onSubmit={handleLogin}>
          <TextField
            id="outlined-email"
            label="E-mail/UserId"
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{
              style: {
                fontSize: '18px', // Change font size
                fontFamily: 'CustomFont',
              },
            }}
          />
          <TextField
            id="outlined-password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            InputLabelProps={{
              style: {
                fontSize: '18px', // Change font size
                fontFamily: 'CustomFont',
              },
            }}
          />
          <a style={{ marginTop: '20px', width: '300px', color: '#AFAFAF' }} href="/forgetpassword" className="forgot-password">Forgot Password?</a>
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
