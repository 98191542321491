import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Container, AppBar, Toolbar, IconButton, Checkbox, FormControlLabel } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { ENDPOINT, Urls } from '../Network/Url';
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

const AttendancePage = () => {
    const navigate = useNavigate();
    const [checkInTime, setCheckInTime] = useState('');
    const [checkOutTime, setCheckOutTime] = useState('');
    const [workingFromHome, setWorkingFromHome] = useState(false);
    const [workingFromHomeCheckOut, setWorkingFromHomeCheckOut] = useState(false);
    console.log(workingFromHome, "in time");
    console.log(workingFromHomeCheckOut, "out time");


    const token = localStorage.getItem("authToken");

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit', // Add seconds to the format
            hour12: true,
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleString('en-IN', options).replace(',', ' ');
    };

    const fetchAttendanceData = () => {
        const myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch(`${ENDPOINT}/${Urls.getAttendance}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Success === 1 && result.Data.length > 0) {
                    const todayAttendance = result.Data[0];
                    if (todayAttendance) {
                        setCheckInTime(formatDateTime(todayAttendance.checkIn));
                        setWorkingFromHome(todayAttendance.isWFHCheckin);
                        if (todayAttendance.checkOut) {
                            setCheckOutTime(formatDateTime(todayAttendance.checkOut));
                            setWorkingFromHomeCheckOut(todayAttendance.isWFHCheckout);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log('Error fetching attendance data:', error);
                toast.error('Failed to fetch attendance data!');
            });
    };

    useEffect(() => {
        fetchAttendanceData();
    }, [token]);

    const handleCheckIn = () => {
        // Fetch the IP address
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                const ipAddress = data.ip; // Get the IP from the response
    
                const myHeaders = new Headers();
                myHeaders.append("authorization", `Bearer ${token}`);
                myHeaders.append("Content-Type", "application/json");
    
                const body = JSON.stringify({
                    "ip": ipAddress,
                    "pic": "https://zomak.s3.eu-central-1.amazonaws.com/ZOM86930/1722589002954_Gaurav_Passport_photo.png",
                    "isWFHCheckin": workingFromHome ? 1 : 0
                });
    
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: body,
                    redirect: 'follow'
                };
    
                // Send the check-in request
                fetch(`${ENDPOINT}/${Urls.checkIn}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.Success === 1) {
                            toast.success(result.Message);
                            fetchAttendanceData();
                            setCheckInTime(formatDateTime(new Date().toISOString()));
                        } else {
                            toast.error(result.Message);
                        }
                    })
                    .catch(error => {
                        console.log('error', error);
                        toast.error('Check-in failed!');
                    });
            })
            .catch(error => {
                console.log('Failed to fetch IP address', error);
                toast.error('Could not retrieve IP address');
            });
    };

    const handleCheckOut = async () => {
        try {
            // Fetch the IP address
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const ip = ipResponse.data.ip;
    
            // Set up headers
            const myHeaders = new Headers();
            myHeaders.append("authorization", `Bearer ${token}`);
            myHeaders.append("Content-Type", "application/json");
    
            // Set the request body with the real IP address and other data
            const body = JSON.stringify({
                ip: ip,
                pic: "https://zomak.s3.eu-central-1.amazonaws.com/ZOM86930/1722589002954_Gaurav_Passport_photo.png",
                isWFHCheckout: workingFromHomeCheckOut ? 1 : 0
            });
    
            // Set up request options
            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: body,
                redirect: 'follow'
            };
    
            // Make the API request
            const response = await fetch(`${ENDPOINT}/${Urls.checkOut}`, requestOptions);
            const result = await response.json();
    
            if (result.Success === 1) {
                toast.success(result.Message);
                fetchAttendanceData();
                setCheckOutTime(formatDateTime(result.Data.checkOutTime));
            } else {
                toast.error('Check-out failed!');
            }
        } catch (error) {
            console.log('Error during check-out:', error);
            toast.error('Check-out failed!');
        }
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const { name, usertype } = userData;

    // Define the role mapping
    const roleMapping = {
        1: 'admin',
        2: 'bde',
        3: 'expert',
        4: 'expert manager',
        5: 'quality',
        6: 'project assigner',
        7: 'client',
    };

    // Determine the role name based on the usertype
    const roleName = roleMapping[usertype] || 'user';

    const [auth, setAuth] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    return (
        <Box sx={{ backgroundColor: '#E8E8E8', minHeight: '100vh' }}>
            <Toaster />
            <AppBar position="static" sx={{ backgroundColor: '#3C455E' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 9 }}
                        onClick={() => navigate('/dashboard')}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ fontFamily: 'CustomFont', flexGrow: 1 }}>
                        Dashboard
                    </Typography>

                    <div>
                        <Typography variant="h6" onClick={handleMenuClick} style={{ cursor: 'pointer', display: 'grid', width: '100%', justifyItems: 'start' }}>
                            <span style={{ fontFamily: 'CustomFont', fontSize: '22px', fontWeight: '700', lineHeight: '29px' }} >{name}</span>
                            <span style={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '400', lineHeight: '24px' }}>as {roleName}</span>
                        </Typography>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={() => {
                                navigate('/attendance')
                            }}>Attendance</MenuItem>
                            <MenuItem onClick={() => {
                                navigate('/profile')
                            }}>Profile</MenuItem>
                            <MenuItem onClick={() => {
                                localStorage.removeItem("authToken");
                                localStorage.removeItem("userData");
                                navigate("/");
                            }}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
            <Container sx={{ width: '348px', height: '272px', borderRadius: '10px' }}>
                <Box
                    sx={{
                        padding: '20px',
                        borderRadius: '8px',
                        marginTop: '69px',
                        textAlign: 'center',
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={workingFromHome}
                                onChange={(e) => setWorkingFromHome(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={
                            <Typography sx={{ fontFamily: 'CustomFont', fontSize: '16px', fontWeight: '400', lineHeight: '24px' }}>
                            Working from home
                            </Typography>
                            }
                    />
                    <Button variant="contained" sx={{ backgroundColor: '#3C455E', marginBottom: '20px', width: '100%', fontFamily: 'CustomFont' }} onClick={handleCheckIn}>
                        <span style={{ fontFamily: 'CustomFont', fontSize: '19px', fontWeight: '700', lineHeight: '24px', letterSpacing: '0.5px',  }}>
                            Check-In
                        </span>
                    </Button>
                    <Typography variant="body1" sx={{ marginBottom: '40px', fontFamily: 'CustomFont' }}>
                        <span style={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', lineHeight: '24px', letterSpacing: '0.5px' }}>
                            {checkInTime}
                        </span>
                    </Typography>
                    {checkInTime && (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={workingFromHomeCheckOut}
                                        onChange={(e) => setWorkingFromHomeCheckOut(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={
                                <Typography sx={{ fontFamily: 'CustomFont', fontSize: '16px', fontWeight: '400', lineHeight: '24px',}}>
                                Working from home
                                </Typography>
                                }
                            />
                            <Button variant="contained" sx={{ backgroundColor: '#3C455E', marginBottom: '20px', width: '100%', fontFamily: 'CustomFont' }} onClick={handleCheckOut}>
                                <span style={{ fontFamily: 'CustomFont', fontSize: '19px', fontWeight: '700', lineHeight: '24px', letterSpacing: '0.5px' }}>
                                    Check-out
                                </span>
                            </Button>
                            <Typography variant="body1" sx={{ fontFamily: 'CustomFont' }}>
                                <span style={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', lineHeight: '24px', letterSpacing: '0.5px' }}>
                                    {checkOutTime}
                                </span>
                            </Typography>
                        </>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export default AttendancePage;

