import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Grid, Button, Fab, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Navbar from './Navbar';
import { ENDPOINT, Urls } from '../Network/Url';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';


function Dashboard() {
    const navigate = useNavigate();
    const [assignments, setAssignments] = useState([]);
    const [completedAssignmentsCount, setCompletedAssignmentsCount] = useState(0);
    const [partialAssignmentsCount, setPartialAssignmentsCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [showPayment, setShowPayment] = useState(false);
    const [showClient, setShowClient] = useState(false);
    const [loading, setLoading] = useState(true);


    const handleAddUserClick = () => {
        navigate('/add-assignment');
    };

    const handleViewAll = () => {
        navigate('/all-assignment');
    }

    const token = localStorage.getItem("authToken");

    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const { name, usertype } = userData;

    useEffect(() => {
        const fetchAssignments = async () => {
            try {
                const response = await fetch(`${ENDPOINT}/${Urls.assignments}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        "pageno": 1,
                        "count": 10
                    }),
                    redirect: 'follow'
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch assignments');
                }

                const data = await response.json();
                if (data.Success === 1) {
                    setAssignments(data.Data.assignments);
                    setCompletedAssignmentsCount(data.Data.stats.completed);
                    setPartialAssignmentsCount(data.Data.stats.ongoing);
                    setTotalCount(data.Data.stats.total_assignments);
                    setShowPayment(data.Data.settings.showPayment);
                    setShowClient(data.Data.settings.showClient);
                } else {
                    throw new Error(data.Message);
                }
            } catch (error) {
                console.error('Error fetching assignments:', error);
                // Handle error state or alert user
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };
        fetchAssignments();
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'none'
    }));

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleString('en-IN', options).replace(',', ' ');
    };

    const handleRowClick = (assignment) => {
        if (assignment.projectStatus === 1) {
            navigate(`/assignment-detail`, { state: { assignment } });
        } else {
            navigate(`/assignment-summary`, { state: { assignment } });
        }
    };

    const handleCardClick = (projectStatus) => {
        if (projectStatus) {
            // Navigate to the all-assignments page with the specific project status
            navigate('/all-assignment', { state: { projectStatus } });
        } else {
            // Navigate to the all-assignments page without specific project status
            navigate('/all-assignment');
        }
    };


    const renderPaymentStatusButton = (paymentStatus) => {
        switch (paymentStatus) {
            case 1:
                return <Typography className='payment' variant="h6" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#9B111E57', color: '#9B111E' }}>due</Typography>;
            case 2:
                return <Typography className='payment' variant="h6" sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#31006257', color: '#310062' }}>partial</Typography>;
            case 3:
                return <Typography className='payment' variant="h6" sx={{
                    fontFamily: 'CustomFont',
                    fontSize: '18px',
                    fontWeight: '700',
                    color: '#006A4E',
                    background: '#006A4E57'
                }}>paid</Typography>;
            default:
                return null;
        }
    };

    const renderProjectStatusButton = (projectStatus) => {
        switch (projectStatus) {
            case 1:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00BFFF57', color: '#00BFFF' }}>created</Typography>;
            case 2:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#B7410E57', color: '#B7410E' }}>on-going</Typography>;
            case 3:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00315357', color: '#003153' }}>qc-passed</Typography>;
            case 4:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#D2B55B57', color: '#D2B55B' }}>qc-rejected</Typography>;
            case 5:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#556B2F57', color: '#556B2F' }}>completed</Typography>;
            case 6:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#70421457', color: '#704214' }}>delivered</Typography>;
            case 7:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#D0312D4D', color: '#D0312D', width: 'fit-content' }}>client-rejected</Typography>;
            default:
                return null;
        }
    };

    const renderAssignments = () => {
        return assignments.map((assignment, index) => (
            <Card key={assignment.assignment_id} variant="notchedOutline" sx={{ marginBottom: '16px', cursor: 'pointer' }} onClick={() => handleRowClick(assignment)}>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} md={4}>
                        <Item sx={{ textAlign: 'left' }}>
                            <Typography variant="h6" sx={{ fontFamily: 'CustomFont', fontWeight: '700', color: 'black' }}>
                                {assignment.title} ({assignment.number_of_words ? assignment.number_of_words : '--'} words)
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Created by {assignment.createdBy.name}, {formatDateTime(assignment.createdTime)}
                            </Typography>
                            {showClient ? <Typography variant="body2" color="textSecondary">
                                Client: {assignment.clientName ? assignment.clientName : "--"}
                            </Typography> : ""}
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item>
                            <Typography variant="h6" sx={{ fontFamily: 'CustomFont', fontWeight: '700', color: 'black' }}>ZOM-{assignment.assignment_id}</Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>

                            {showPayment ? <Typography sx={{ fontFamily: 'CustomFont' }} variant="h6">₹{assignment.budget ? assignment.budget : "--"}</Typography> : ""}

                            {showPayment ? renderPaymentStatusButton(assignment.paymentStatus) : null}
                            {renderProjectStatusButton(assignment.projectStatus)}
                        </Item>
                    </Grid>
                </Grid>
                <hr />
            </Card>
        ));
    };

    return (
        <>
            <Navbar />
            <Box p={2}>
                {loading ? ( // Render the loader while loading
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Grid container spacing={4} sx={{ padding: { xs: '10px', sm: '20px', md: '70px' } }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card sx={{ background: '#3C455E1C', boxShadow: 'none', cursor: 'pointer' }}
                                    onClick={() => handleCardClick('all')}>
                                    <CardContent sx={{ width: '100%', height: '141px', display: 'grid', alignContent: 'center', padding: '0px', gap: '16px' }}>
                                        <Typography sx={{ fontFamily: 'CustomFont', fontSize: '22px', fontWeight: '400', lineHeight: '39px' }}
                                            variant="h6">Total Assignment</Typography>
                                        <Typography sx={{ fontFamily: 'CustomFont', fontWeight: '700' }} variant="h4">{totalCount}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card sx={{ background: '#3C455E1C', boxShadow: 'none', cursor: 'pointer' }}
                                    onClick={() => handleCardClick(5)}>
                                    <CardContent sx={{ width: '100%', height: '141px', display: 'grid', alignContent: 'center', padding: '0px', gap: '16px' }}>
                                        <Typography sx={{ fontFamily: 'CustomFont', fontSize: '22px', fontWeight: '400', lineHeight: '39px' }}
                                            variant="h6">Completed</Typography>
                                        <Typography sx={{ fontFamily: 'CustomFont', fontWeight: '700' }} variant="h4">{completedAssignmentsCount}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Card sx={{ background: '#3C455E1C', boxShadow: 'none', cursor: 'pointer' }}
                                    onClick={() => handleCardClick(2)}>
                                    <CardContent sx={{ width: '100%', height: '141px', display: 'grid', alignContent: 'center', padding: '0px', gap: '16px' }}>
                                        <Typography sx={{ fontFamily: 'CustomFont', fontSize: '22px', fontWeight: '400', lineHeight: '39px' }}
                                            variant="h6">Working On</Typography>
                                        <Typography sx={{ fontFamily: 'CustomFont', fontWeight: '700' }} variant="h4">{partialAssignmentsCount}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Box mt={2}>
                            {renderAssignments()}
                        </Box>
                        <Box textAlign="center" mt={2}>
                            <Button sx={{ background: '#3C455E', fontFamily: 'CustomFont' }} variant="contained" color="primary" onClick={() => handleCardClick('all')}>View All</Button>
                        </Box>
                        {usertype !== 3 && usertype !== 7 && (
                            <Fab
                                color="primary"
                                aria-label="add"
                                sx={{
                                    position: 'fixed',
                                    bottom: 16,
                                    right: 16,
                                    background: '#3C455E',
                                }}
                                onClick={handleAddUserClick}
                            >
                                <AddIcon sx={{ width: '100%', height: '100%' }} />
                            </Fab>
                        )}
                    </>
                )}
            </Box>
        </>
    );
}

export default Dashboard;
