import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    TextField,
    Grid,
    Button,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Card,
    CardContent,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssignmentNavbar from './AssignmentNavbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { ENDPOINT, Urls } from '../Network/Url';
import toast from 'react-hot-toast';
import ReactQuill from 'react-quill';

const UploadBox = styled(Box)(({ theme }) => ({
    border: '2px dashed #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 150,
    cursor: 'pointer'
}));

function EditAssignment() {
    const { state } = useLocation();
    const { assignment } = state || {};
    const navigate = useNavigate();
    console.log(assignment, "qulaity")

    const token = localStorage.getItem("authToken");

    const [hardDeadline, setHardDeadline] = useState(null);
    const [softDeadline, setSoftDeadline] = useState(null);
    const [scheduleOn, setScheduleOn] = useState(null);
    const [bdeOptions, setBdeOptions] = useState([]);
    const [expertOptions, setExpertOptions] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [subjectOptions, setSubjectOptions] = useState([]);  // Initialized as an array
    const [expertManagerOptions, setExpertManagerOptions] = useState([]);
    const [projectAssignerOptions, setProjectAssignerOptions] = useState([]);
    const [qualityOptions, setQualityOptions] = useState([]);
    const [subject, setSubject] = useState('');
    const [files, setFiles] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [open, setOpen] = useState(false);



    const [assignments, setAssignments] = useState({
        title: assignment.title || '',
        client: assignment.client.userId || '',
        bde: assignment.bde.userId || '',
        expertManager: assignment.expert_manager.userId || '',
        subject: assignment.subject || '',
        scheduleOn: assignment.schedule_on || '',
        softDeadline: assignment.soft_deadline ? dayjs(assignment.soft_deadline) : dayjs(),
        hardDeadline: assignment.hard_deadline ? dayjs(assignment.hard_deadline) : dayjs(),
        budget: assignment.budget || 0,
        paymentReceived: assignment.paymentReceived || 0,
        details: assignment.details || '',
        uploadDocs: [],
        docs: assignment.docs || [],
        quality: assignment.quality
    });

    const handleChange = (name, value) => {
        setAssignments((prev) => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (name, date) => {
        setAssignments((prev) => ({ ...prev, [name]: dayjs(date) }));
    };

    const handleFileChange = async (e) => {
        const newFiles = Array.from(e.target.files);
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);

        const token = localStorage.getItem("authToken");

        for (const file of newFiles) {
            const formdata = new FormData();
            formdata.append("file", file);

            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    "authorization": `Bearer ${token}`,
                }),
                body: formdata,
                redirect: 'follow'
            };

            try {
                const response = await fetch(`${ENDPOINT}/${Urls.uploadFile}`, requestOptions);
                const result = await response.json();
                if (result.Success === 1) {
                    setFileData((prevData) => [...prevData, { url: result.Data.url, name: file.name }]);
                } else {
                    console.error('Failed to upload file:', result.Message);
                    toast.error(result.Message);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setFileData((prevData) => prevData.filter((_, i) => i !== index)); // Remove URL and name as well
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${ENDPOINT}/${Urls.assignmentUser}`, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.Success) {
                    const combinedOptions = [
                        ...result.Data.bde,
                        ...result.Data['expert-manager'],
                        ...result.Data['project-assigner']
                    ];
                    setBdeOptions(combinedOptions);
                    setExpertOptions(result.Data.expert);
                    setClientOptions(result.Data.client || []);
                    setExpertManagerOptions(result.Data['expert-manager']);
                    setProjectAssignerOptions(result.Data['project-assigner']);
                    setQualityOptions(result.Data.quality);
                    setSubjectOptions(Array.isArray(result.Data.subjects) ? result.Data.subjects : []);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token]);


    const handleDeleteDoc = (index) => {
        setAssignments((prev) => ({
            ...prev,
            docs: prev.docs.filter((_, i) => i !== index)
        }));
    };

    const handleSave = async () => {
        const token = localStorage.getItem("authToken");

        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const payload = {
            ...(assignments.title && { title: assignments.title }),
            ...(assignments.details && { details: assignments.details }),
            ...(assignments.client && { client_id: assignments.client }),
            ...(assignments.bde && { bde_id: assignments.bde }),
            ...(assignments.expertManager && { expert_manager_id: assignments.expertManager }),
            ...(assignments.subject && { subject: assignments.subject }),
            ...(assignments.assignment_id && { project_assigner: assignments.assignment_id }),
            ...(assignments.hardDeadline && { hard_deadline: dayjs(assignments.hardDeadline).format('YYYY-MM-DD HH:mm:ss') }),
            ...(assignments.softDeadline && { soft_deadline: dayjs(assignments.softDeadline).format('YYYY-MM-DD HH:mm:ss') }),
            ...(assignments.scheduleOn && { schedule_on: assignments.scheduleOn }),
            ...(assignments.budget && { budget: assignments.budget }),
            ...(assignment.projectStatus && { projectStatus: assignment.projectStatus }),
            ...(assignment.number_of_words && { number_of_words: assignment.number_of_words }),
            ...(assignments.docs.length > 0 && { docs: [...assignments.docs, ...fileData] }),
        };

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(payload),
            redirect: 'follow'
        };

        try {
            console.log("Sending request with payload:", payload);
            const response = await fetch(`${ENDPOINT}/${Urls.editAssignemt}/${assignment.assignment_id}`, requestOptions);

            // Check for HTTP errors
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error('HTTP error:', response.status, errorMessage);
                return;
            }

            const result = await response.json();

            // Log the result for debugging
            console.log('Result:', result);

            if (result.Success === 1) {
                console.log('Assignment updated successfully:', result);
                toast.success(result.Message);
                navigate('/dashboard');  // Navigate back to the assignments list page
            } else {
                console.error('Error updating assignment:', result.Message);
                toast.error(result.Message);
            }
        } catch (error) {
            console.error('Error updating assignment:', error);
        }
    };

    const handleDeleteClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await fetch(`${ENDPOINT}/${Urls.editAssignemt}/${assignment.assignment_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // replace with actual token
                },
                body: JSON.stringify({
                    'isActive': 0
                }),
            });

            const result = await response.json();

            if (result.Success) {
                console.log('Assignment updated successfully:', result);
                toast.success(result.Message);
                navigate('/dashboard');  // Navigate back to the assignments list page
            } else {
                console.error('Error updating assignment:', result.Message);
                toast.error(result.Message);
            }
        } catch (error) {
            console.error('Error deleting assignment:', error);
            toast.error('An error occurred while deleting the assignment');
        } finally {
            setOpen(false);
        }
    };

    const handleCancelDelete = async () => {
        try {
            const response = await fetch(`${ENDPOINT}/${Urls.editAssignemt}/${assignment.assignment_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // replace with actual token
                },
                body: JSON.stringify({
                    isActive: 1,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update assignment');
            }

            console.log('Assignment marked as inactive');
        } catch (error) {
            console.error('Error updating assignment:', error);
        } finally {
            setOpen(false);
        }
    };


    const handleDelete = () => {
        console.log('Assignment deleted');
    };

    return (
        <>
            <AssignmentNavbar />
            <Box p={2} sx={{ background: '#E8E8E8' }}>
                <Typography variant="h6"
                    sx={{
                        textAlign: 'left',
                        ml: 2,
                        fontFamily: 'CustomFont',
                        fontWeight: 700,
                        fontSize: '22px',
                        lineHeight: '39px'
                    }}>
                    Edit Assignment
                </Typography>
                <Box sx={{ background: '#fff', borderRadius: 2, p: 3, mt: 2 }}>
                    <Grid container spacing={2} sx={{ mt: 2, textAlign: 'left' }}>
                        <Grid item xs={12}>
                            <TextField
                                label="Title"
                                fullWidth
                                name="title"
                                value={assignments.title}
                                onChange={handleChange}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontFamily: 'CustomFont', // Your custom font here
                                        fontWeight: 500,
                                        fontSize: '17px',
                                        lineHeight: '28px',
                                        color: '#1D1B20'

                                    },
                                    '& .MuiInputLabel-root': {
                                        fontFamily: 'CustomFont', // Custom font for the label
                                        color: '#1D1B20',
                                        fontSize: '17px'
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="client-label" sx={{
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20'
                                }}>Client Name</InputLabel>
                                <Select
                                    labelId="clientName-label"
                                    label="Client Name"
                                    name="client"
                                    value={assignments.client}
                                    onChange={handleChange}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont', // Your custom font here
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'

                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont', // Custom font for the label
                                            color: '#1D1B20'
                                        },
                                        '& .MuiSelect-select': {
                                            fontFamily: 'CustomFont', // Custom font for the selected value
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'
                                        },
                                    }}
                                >
                                    {clientOptions.map((option) => (
                                        <MenuItem
                                            sx={{
                                                fontFamily: 'CustomFont',
                                                fontWeight: 500,
                                                fontSize: '17px',
                                                lineHeight: '28px',
                                                color: '#1D1B20'
                                            }}
                                            key={option.userId} value={option.userId}>
                                            {option.name} ({option.userId})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="bde-label" sx={{
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20'
                                }}>BDE</InputLabel>
                                <Select
                                    labelId="bde-label"
                                    label="BDE"
                                    name="bde"
                                    value={assignments.bde}
                                    onChange={handleChange}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont', // Your custom font here
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'

                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont', // Custom font for the label
                                            color: '#1D1B20'
                                        },
                                    }}
                                >
                                    {bdeOptions.map((option) => (
                                        <MenuItem sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'
                                        }} key={option.userId} value={option.userId}>
                                            {option.name} ({option.userId})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="expert-manager-label" sx={{
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20'
                                }}>Expert Manager</InputLabel>
                                <Select
                                    labelId="expert-manager-label"
                                    label="Expert Manager"
                                    name="expertManager"
                                    value={assignments.expertManager}
                                    onChange={handleChange}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont', // Your custom font here
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'

                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont', // Custom font for the label
                                            color: '#1D1B20'
                                        },
                                    }}
                                >
                                    {expertManagerOptions.map((option) => (
                                        <MenuItem sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'
                                        }} key={option.userId} value={option.userId}>
                                            {option.name} ({option.userId})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="subject-list-label" sx={{
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20'
                                }}>Subject List</InputLabel>
                                <Select
                                    labelId="subject-list-label"
                                    label="Subject List"
                                    name="subject"
                                    value={assignments.subject ? assignments.subject : "--"}
                                    onChange={handleChange}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont', // Your custom font here
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'

                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont', // Custom font for the label
                                            color: '#1D1B20'
                                        },
                                    }}
                                >
                                    {subjectOptions.map(subject => (
                                        <MenuItem sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'
                                        }} key={subject} value={subject}>{subject}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', gap: '7%' }}>
                            <Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Soft Deadline"
                                        value={assignments.softDeadline}
                                        onChange={(date) => handleDateChange('softDeadline', date)}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontFamily: 'CustomFont', // Your custom font here
                                                fontWeight: 500,
                                                fontSize: '17px',
                                                lineHeight: '28px',
                                                color: '#1D1B20'

                                            },
                                            '& .MuiInputLabel-root': {
                                                fontFamily: 'CustomFont', // Custom font for the label
                                                color: '#1D1B20'
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="Hard Deadline"
                                        value={assignments.hardDeadline}
                                        onChange={(date) => handleDateChange('hardDeadline', date)}
                                        renderInput={(params) => <TextField fullWidth {...params} />}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontFamily: 'CustomFont', // Your custom font here
                                                fontWeight: 500,
                                                fontSize: '17px',
                                                lineHeight: '28px',
                                                color: '#1D1B20'

                                            },
                                            '& .MuiInputLabel-root': {
                                                fontFamily: 'CustomFont', // Custom font for the label
                                                color: '#1D1B20'
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', gap: '7%' }}>
                            <Grid sx={{ width: '19%' }}>
                                <TextField
                                    label="Budget"

                                    name="budget"
                                    type="number"
                                    value={assignments.budget}
                                    onChange={handleChange}
                                    sx={{
                                        width: '100%',
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont', // Your custom font here
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'

                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont', // Custom font for the label
                                            color: '#1D1B20'
                                        },
                                    }}
                                />
                            </Grid>
                            {/* <Grid sx={{ width: '19%' }}>
                                <TextField
                                    label="Payment Received"
                                    fullWidth
                                    name="paymentReceived"
                                    type="number"
                                    value={assignments.paymentReceived}
                                    onChange={handleChange}
                                />
                            </Grid> */}
                        </Grid>
                        <Grid item xs={12}>
                            <label
                                style={{
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20',
                                    fontSize: '17px',
                                    marginBottom: '8px',
                                    display: 'block',
                                }}
                            >
                                Details
                            </label>
                            <ReactQuill
                                theme="snow"
                                value={assignments.details}
                                onChange={(value) => handleChange('details', value)}
                                style={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 500,
                                    fontSize: '17px',
                                    lineHeight: '28px',
                                    color: '#1D1B20',
                                }}
                                modules={{
                                    toolbar: [
                                        [{ header: [1, 2, 3, false] }],
                                        ['bold', 'italic', 'underline', 'strike'],
                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                        ['link', 'image'],
                                        ['clean'],
                                    ],
                                }}
                            />
                        </Grid>
                        {assignment && assignment.docs && (
                            <>
                                <Typography variant="h6" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 700,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                    color: '#000000',
                                    mt: 2,
                                    width: '100%',
                                    ml: 2

                                }}>Uploaded Docs
                                </Typography>
                                <Box sx={{ display: 'grid', ml: 2 }}>
                                    {assignments.docs.map((doc, index) => (
                                        <React.Fragment key={index}>
                                            <div style={{ display: 'flex' }}>
                                                <Typography variant="body1" sx={{
                                                    mt: 1,
                                                    fontFamily: 'CustomFont',
                                                    fontWeight: 500,
                                                    color: '#7C7C7C',
                                                    fontSize: '18px',
                                                    lineHeight: '20px',
                                                }}>{doc.name}</Typography>
                                                <IconButton aria-label="delete" onClick={() => handleDeleteDoc(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            </>
                        )}
                        <Grid item xs={12} sx={{ textAlign: 'left' }}>
                            {/* <Typography mb={2} variant="h6">Upload Docs</Typography> */}
                            <input

                                style={{ display: 'none' }}
                                id="upload-docs"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="upload-docs" style={{ display: 'contents' }} >
                                <UploadBox sx={{ width: '10%' }}>
                                    <AddIcon fontSize="large" />
                                </UploadBox>
                            </label>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                {files.map((file, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                                        <Typography variant="body1">{file.name}</Typography>
                                        <IconButton aria-label="delete" onClick={() => handleRemoveFile(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={11}>
                                <Button variant="contained" sx={{
                                    width: '186px',
                                    height: '40px',
                                    borderRadius: '10px',
                                    background: '#3C455E'
                                }} onClick={handleSave}>
                                    <Typography sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 700,
                                        textTransform: 'capitalize',
                                        fontSize: '19px',
                                        lineHeight: '24px',
                                        color: 'white'

                                    }}>
                                        Save
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={1} sx={{ alignContent: 'center' }}>
                                <Typography variant="contained" color="#3C455E" sx={{ cursor: 'pointer' }} onClick={handleDeleteClick}>
                                    Delete
                                </Typography>
                            </Grid>
                            <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>Are you sure?</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Do you really want to delete this assignment? This action cannot be undone.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCancelDelete} color="primary">
                                        No
                                    </Button>
                                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                                        Yes
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default EditAssignment;
