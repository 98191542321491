import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    Grid,
    Button,
    TextField,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Avatar,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    IconButton,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import DOMPurify from 'dompurify';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import AssignmentNavbar from './AssignmentNavbar'; // Assuming you have this component
import { Title } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import { ENDPOINT, Urls } from '../Network/Url';
import toast, { Toaster } from 'react-hot-toast';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';


const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none'
}));

function AssignmentDetails() {

    const { state } = useLocation();
    const { assignment } = state || {};
    const navigate = useNavigate()
    const token = localStorage.getItem("authToken");
    const userData = JSON.parse(localStorage.getItem('userData')) || {};
    const userType = userData.usertype;

    const [expertOptions, setExpertOptions] = useState([]);
    const [expertId, setExpertId] = useState([]);
    const [qualityOptions, setQualityOptions] = useState([]);
    const [qualityId, setQualityId] = useState('');
    const [assignmentDetail, setAssignmentDetail] = useState({});
    const [payments, setPayments] = useState()
    const [paymentAmount, setPaymentAmount] = useState('');
    const [modeOfPayment, setModeOfPayment] = useState('');
    const [showPayment, setShowPayment] = useState(0);
    const [showClient, setShowClient] = useState(0);
    const [isCanUpdate, setIsCanUpdate] = useState(0);
    const [isCanEdit, setIsCanEdit] = useState(0);

    console.log("assign", assignmentDetail.showHeadDeadLine);

    useEffect(() => {


        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${ENDPOINT}/${Urls.assignmentDetail}/${assignment.assignment_id}`, {
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`
                }
            });
            const result = await response.json();
            if (result.Success) {
                // setBdeOptions(result.Data.bde);
                // setExpertOptions(result.Data.expert.map((expert) => ({ ...expert, words: '' })));
                setAssignmentDetail(result.Data);
                setSelectedExperts(result.Data.experts === null ? [] : result.Data.experts);
                setPayments(result.Data.payments);
                setQualityId(result.Data.quality.userId === null ? "" : result.Data.quality.userId);
                setShowPayment(result.Data.showPayment);
                setShowClient(result.Data.showClient);
                setIsCanUpdate(result.Data.isCanUpdate);
                setIsCanEdit(result.Data.isCanEdit);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSavePayment = () => {
    };

    const handleRemovePayment = (paymentId) => {
        var myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "assignmentId": assignment.assignment_id,
            "paymentId": paymentId
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${ENDPOINT}/${Urls.removePayment}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result);
                window.location.reload(); // Log or handle successful removal
                // You may want to update state or trigger a refresh of data here
            })
            .catch(error => console.log('error', error));
    };

    const saveQuality = async (qualityId) => {
        const token = localStorage.getItem("authToken");

        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            quality: qualityId
        });

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            console.log("Sending request with payload:", raw);
            const response = await fetch(`${ENDPOINT}/${Urls.editAssignemt}/${assignment.assignment_id}`, requestOptions);

            // Check for HTTP errors
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error('HTTP error:', response.status, errorMessage);
                return;
            }

            const result = await response.json();

            setQualityId(qualityId)

            // Log the result for debugging
            console.log('Result:', result);

            if (result.Success) {
                console.log('Assignment updated successfully:', result);
                toast.success(result.Message);  // Navigate back to the assignments list page
            } else {
                console.error('Error updating assignment:', result.Message);
            }
        } catch (error) {
            console.error('Error updating assignment:', error);
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${ENDPOINT}/${Urls.assignmentUser}`, {
                    method: 'GET',
                    headers: {
                        'authorization': `Bearer ${token}`
                    }
                });
                const result = await response.json();
                if (result.Success) {
                    // setBdeOptions(result.Data.bde);
                    setExpertOptions(result.Data.expert.map((expert) => ({ ...expert, numberOfWords: '' })));
                    // setClientOptions(result.Data.client);
                    // setExpertManagerOptions(result.Data['expert-manager']);
                    // setProjectAssignerOptions(result.Data['project-assigner']);
                    setQualityOptions(result.Data.quality);
                    // setSubjectOptions(Array.isArray(result.Data.subjects) ? result.Data.subjects : []);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const [quality, setQuality] = useState('');
    const [selectedExperts, setSelectedExperts] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentExpert, setCurrentExpert] = useState(null);
    const [numWords, setNumWords] = useState('');

    const [openPay, setOpenPay] = useState(false);

    const handleClickOpen = () => {
        setOpenPay(true);
    };

    const handleClose = () => {
        setOpenPay(false);
    };

    const handleNumberOfWords = (e, index) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
        setNumWords(value);
        const updatedExpert = currentExpert.map((expert, exIndex) => {
            if (index == exIndex) {
                return { ...expert, numberOfWords: value }
            }
            else {
                return expert
            }
        })
        setCurrentExpert([...updatedExpert]);
    };

    const handleAddExpert = (event) => {
        const expert = event.target.value;
        const expertss = expert.filter((expert) => {
            if (assignmentDetail.experts === null) return true

            return !assignmentDetail.experts.some(ex => ex.userId == expert.userId)
        })
        if (!selectedExperts.includes(expert)) {
            setCurrentExpert(expertss);
            setOpen(true);
            // setSelectedExperts(assignmentDetail.experts === null ? [] : assignmentDetail.experts )
        }
    };

    const handleSaveExpert = async () => {
        const token = localStorage.getItem("authToken");

        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        let payload;

        if (currentExpert.length === 1) {
            // Single expert case
            payload = {
                expertId: currentExpert[0].userId,
                assignmentId: assignment.assignment_id,
                numberOfWords: parseInt(currentExpert[0].numberOfWords),
                totalNoOfWords: 22000  // Example value, adjust as needed
            };
        } else {
            // Multiple experts case
            payload = currentExpert.map(expert => ({
                expertId: expert.userId,
                assignmentId: assignment.assignment_id,
                numberOfWords: parseInt(expert.numberOfWords),
                totalNoOfWords: 22000  // Example value, adjust as needed
            }));
        }

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(payload),
            redirect: 'follow'
        };

        try {
            console.log("Sending request with payload:", payload);
            const response = await fetch(`${ENDPOINT}/${Urls.addExpert}`, requestOptions);

            // Check for HTTP errors
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error('HTTP error:', response.status, errorMessage);
                toast.error(`HTTP error: ${response.status} ${errorMessage}`);
                return;
            }

            const result = await response.json();

            // Log the result for debugging
            console.log('Result:', result);

            if (result.Success) {
                console.log('Expert(s) added successfully:', result);
                toast.success(result.Message);  // Optional: Show success message

                setOpen(false);
                setNumWords(numWords);
                fetchData()
            } else {
                console.error('Error adding expert(s):', result.Message);
                toast.error(result.Message);  // Optional: Show error message
            }
        } catch (error) {
            console.error('Error adding expert(s):', error);
            toast.error('Failed to add expert(s)');  // Optional: Show generic error message
        }
    };

    const handleRemoveFile = () => {
        console.log("deleted")
    };


    const handleRemoveExpert = async (expert) => {
        const token = localStorage.getItem("authToken");

        if (!token) {
            console.error("Token not found in localStorage");
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");

        let payload = {
            expertId: expert.userId,
            assignmentId: assignment.assignment_id,
        };
        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(payload),
            redirect: 'follow'
        };

        try {
            console.log("Sending request with payload:", payload);
            const response = await fetch(`${ENDPOINT}/${Urls.removeExpert}`, requestOptions);

            // Check for HTTP errors
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error('HTTP error:', response.status, errorMessage);
                toast.error(`HTTP error: ${response.status} ${errorMessage}`);
                return;
            }

            const result = await response.json();

            // Log the result for debugging
            console.log('Result:', result);

            if (result.Success) {
                console.log('Expert(s) added successfully:', result);
                toast.success(result.Message);  // Optional: Show success message

                setOpen(false);
                setNumWords(numWords);
                fetchData()
            } else {
                console.error('Error adding expert(s):', result.Message);
                toast.error(result.Message);  // Optional: Show error message
            }
        } catch (error) {
            console.error('Error adding expert(s):', error);
            toast.error('Failed to add expert(s)');  // Optional: Show generic error message
        }
    };

    const handleQualityChange = (event) => {
        setQuality(event.target.value);
    };

    const renderProjectStatusButton = (projectStatus) => {
        switch (projectStatus) {
            case 1:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00BFFF57', color: '#00BFFF' }}>created</Typography>;
            case 2:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#B7410E57', color: '#B7410E' }}>on-going</Typography>;
            case 3:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#00315357', color: '#003153' }}>qc-passed</Typography>;
            case 4:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#D2B55B57', color: '#D2B55B' }}>qc-rejected</Typography>;
            case 5:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#556B2F57', color: '#556B2F' }}>completed</Typography>;
            case 6:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#70421457', color: '#704214' }}>delivered</Typography>;
            case 7:
                return <Typography variant="h6" className='status' sx={{ fontFamily: 'CustomFont', fontSize: '18px', fontWeight: '700', background: '#D0312D4D', color: '#D0312D', width: 'fit-content' }}>client-rejected</Typography>;
            default:
                return null;
        }
    };

    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'UTC',
        };
        return new Date(dateString).toLocaleString('en-IN', options).replace(',', ' ');
    };

    const handleEdit = () => {
        navigate('/edit-assignment', { state: { assignment: assignmentDetail } })
    }

    const downloadAllFiles = async () => {
        const zip = new JSZip();

        for (const doc of assignmentDetail.docs) {
            try {
                const response = await fetch(doc.url);
                if (!response.ok) {
                    throw new Error(`Failed to fetch ${doc.url}: ${response.status} ${response.statusText}`);
                }
                const blob = await response.blob();
                zip.file(doc.name, blob);
            } catch (error) {
                console.error('Error fetching file:', error);
                // Handle error gracefully, e.g., notify user or retry later
            }
        }

        zip.generateAsync({ type: 'blob' })
            .then(blob => {
                saveAs(blob, 'files.zip');
            })
            .catch(error => {
                console.error('Error generating zip:', error);
            });
    };

    return (
        <div>
            <AssignmentNavbar />
            <Toaster />
            <Box p={2} sx={{ background: '#E8E8E8', textAlign: 'left' }}>
                <Box>
                    <Typography variant="h6" sx={{
                        paddingLeft: '20px',
                        fontFamily: 'CustomFont',
                        fontWeight: 700,
                        fontSize: '22px'
                    }}>Assignment - ZOM-{assignmentDetail.assignment_id ? assignmentDetail.assignment_id : "--"}</Typography>
                    {/* <Button onClick={() => console.log('Back button clicked')} sx={{ textTransform: 'none' }}>
                        &lt; Back
                    </Button> */}
                </Box>
                <Box sx={{ background: '#fff', borderRadius: 2, p: 3, mt: 2, textAlign: 'left' }}>
                    <Typography variant="h5" sx={{
                        fontFamily: 'CustomFont',
                        fontWeight: 700,
                        fontSize: '21px' // Use the medium font weight defined in your CSS
                    }}>
                        Title
                    </Typography>
                    <Typography variant="h5" sx={{
                        fontFamily: 'CustomFont',
                        fontWeight: 500,
                        fontSize: '21px' // Use the medium font weight defined in your CSS
                    }}>{assignmentDetail.title ? assignmentDetail.title : "--"}</Typography>
                    <Chip label={assignmentDetail.projectStatus ? renderProjectStatusButton(assignmentDetail.projectStatus) : "--"} sx={{ mt: 1, background: 'none', textAlign: 'center', display: 'contents' }} />

                    <Grid container spacing={2} sx={{ mt: 2, textAlignLast: 'left', marginLeft: '-21px' }}>

                        <Grid item xs={12} md={4}>
                            <Item>
                                <Typography variant="body2" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 700,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                    color: '#000000'
                                }}>Deadline</Typography>
                                <Typography variant="body1" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 500,
                                    fontSize: '21px',
                                    lineHeight: '24px'
                                }}>{assignmentDetail.soft_deadline ? formatDateTime(assignmentDetail.soft_deadline) : "--"}</Typography>
                            </Item>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Item>
                                <Typography variant="body2" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 700,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                    color: '#000000'
                                }}>Hard Deadline</Typography>
                                <Typography variant="body1" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 500,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                }}>{assignmentDetail.hard_deadline ? formatDateTime(assignmentDetail.hard_deadline) : "--"}</Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <Typography variant="body2" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 700,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                    color: '#000000'
                                }}>Schedule On</Typography>
                                <Typography variant="body1" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 500,
                                    fontSize: '21px',
                                    lineHeight: '24px'
                                }}>{assignmentDetail.schedule_on ? formatDateTime(assignmentDetail.schedule_on) : "--"}</Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Item>
                                <Typography variant="body2" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 700,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                    color: '#000000'
                                }}>Expert Manager</Typography>
                                <Typography variant="body1" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 500,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                }}>{assignmentDetail.expert_manager
                                    ? assignmentDetail.expert_manager.name
                                    : "--"}</Typography>
                            </Item>
                        </Grid>

                        {userType === 1 && (
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Typography variant="body2" sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 700,
                                        fontSize: '21px',
                                        lineHeight: '24px',
                                        color: '#000000'
                                    }}>BDE</Typography>
                                    <Typography variant="body1" sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 500,
                                        fontSize: '21px',
                                        lineHeight: '24px',
                                    }}>{assignmentDetail.bde ? assignmentDetail.bde.name : '--'}</Typography>
                                </Item>
                            </Grid>
                        )}
                        <Grid item xs={12} md={4}>
                            <Item>
                                <Typography variant="body2" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 700,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                    color: '#000000'
                                }}>Subject</Typography>
                                <Typography variant="body1" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 500,
                                    fontSize: '21px',
                                    lineHeight: '24px',
                                }}>{assignmentDetail.subject ? assignmentDetail.subject : "--"}</Typography>
                            </Item>
                        </Grid>
                        {userType === 1 && (
                            <Grid item xs={12} md={4}>
                                <Item>
                                    <Typography variant="body2" sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 700,
                                        fontSize: '21px',
                                        lineHeight: '24px',
                                        color: '#000000'
                                    }}>BDE</Typography>
                                    <Typography variant="body1" sx={{
                                        fontFamily: 'CustomFont',
                                        fontWeight: 500,
                                        fontSize: '21px',
                                        lineHeight: '24px',
                                    }}>{assignmentDetail.bde ? assignmentDetail.bde.name : '--'}</Typography>
                                </Item>
                            </Grid>
                        )}
                        <Grid item xs={12} md={4}>
                            <Item>
                                {showPayment ?
                                    <>
                                        <Typography variant="body2" sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 700,
                                            fontSize: '21px',
                                            lineHeight: '24px',
                                            color: '#000000'
                                        }}>Budget</Typography>
                                        <Typography variant="body1" sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '21px',
                                            lineHeight: '24px',
                                        }}>₹{assignmentDetail.budget ? assignmentDetail.budget : '--'}</Typography>
                                    </> : ""}
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Item>
                                {showClient ?
                                    <>
                                        <Typography variant="body2" sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 700,
                                            fontSize: '21px',
                                            lineHeight: '24px',
                                            color: '#000000'
                                        }}>Client Name</Typography>
                                        <Typography variant="body1" sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '21px',
                                            lineHeight: '24px',
                                        }}>{assignmentDetail.client ? assignmentDetail.client.name : "--"} ({assignmentDetail.client ? assignmentDetail.client.userId : "--"})</Typography>
                                    </>
                                    : ""}
                            </Item>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Item sx={{ width: '79%' }}>
                                {showPayment ?
                                    <>
                                        <Typography variant="body2" sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 700,
                                            fontSize: '21px',
                                            lineHeight: '24px',
                                            color: '#000000'
                                        }}>Payment Received</Typography>
                                        <Typography variant="body1" sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '21px',
                                            lineHeight: '20px',
                                        }}>₹ {assignmentDetail.total_payment_received ? assignmentDetail.total_payment_received : "--"}</Typography>
                                    </> : ""
                                }
                            </Item>
                        </Grid>
                    </Grid>

                    {showPayment ?
                        <Button variant="contained" sx={{
                            background: '#3C455E',
                            width: '130px',
                            height: '42px',
                            borderRadius: '10px'
                        }}
                            onClick={handleClickOpen}
                        >
                            <Typography sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 500,
                                textTransform: 'capitalize'
                            }}>
                                Add
                            </Typography>
                        </Button>
                        : ""}
                    <Typography variant="h6" sx={{
                        fontFamily: 'CustomFont',
                        fontWeight: 700,
                        fontSize: '21px',
                        lineHeight: '24px',
                        color: '#000000',
                        mt: 5
                    }}>Details</Typography>

                    <Typography variant="body1" sx={{
                        fontFamily: 'CustomFont',
                        fontWeight: 500,
                        color: '#7C7C7C',
                        fontSize: '18px',
                        lineHeight: '24px',
                    }} component="div">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assignmentDetail.details) }} />
                    </Typography>
                    {assignmentDetail && assignmentDetail.docs && (
                        <>
                            <Typography variant="h6" sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 700,
                                fontSize: '21px',
                                lineHeight: '20px',
                                color: '#000000',
                                mt: 5
                            }}>
                                Uploaded Docs <IconButton
                                    onClick={downloadAllFiles}
                                    sx={{
                                        background: '#3C455E', color: 'white',
                                        '&:hover': { background: '#3C455E', color: 'white' }
                                    }}
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {assignmentDetail.docs.map((doc, index) => (
                                    <>
                                        <Typography key={index} variant="body1" sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            color: '#7C7C7C',
                                            fontSize: '18px',
                                            lineHeight: '20px',
                                        }}>{doc.name}</Typography>

                                    </>
                                ))}
                            </Box>
                        </>
                    )}

                    {userType !== 2 && (
                        <>
                            <FormControl fullWidth variant="outlined" sx={{ mt: 6 }}>
                                <InputLabel id="expert-label" sx={{
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20',
                                    fontWeight: 400
                                }}>Select Experts</InputLabel>
                                <Select
                                    labelId="expert-label"
                                    label="Select Experts"
                                    multiple
                                    value={selectedExperts}
                                    onChange={handleAddExpert}
                                    renderValue={(selected) => selected.map((expert) => `${expert.name} (${expert.userId}) - ${expert.numberOfWords}`).join(', ')}
                                    // displayEmpty
                                    disabled={isCanUpdate !== 1}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont', // Your custom font here
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'

                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont', // Custom font for the label
                                            color: '#1D1B20'
                                        },
                                        '& .MuiSelect-select': {
                                            fontFamily: 'CustomFont', // Custom font for the selected value
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'
                                        },
                                    }}
                                >
                                    {expertOptions.map((option) => (
                                        <MenuItem sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'
                                        }} key={option.userId} value={option}>
                                            {option.name} ({option.userId})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Box sx={{ mt: 2 }}>
                                {selectedExperts.map((expert) => (
                                    <Chip
                                        key={expert.name}
                                        label={`${expert.name} (${expert.userId}) - ${expert.numberOfWords} words`}
                                        onDelete={isCanUpdate === 1 ? () => handleRemoveExpert(expert) : null}
                                        deleteIcon={<CancelIcon sx={{ background: 'white', borderRadius: '20px' }} />}
                                        sx={{
                                            mr: 1,
                                            mb: 1,
                                            color: '#FFFFFF',
                                            background: '#3C455E',
                                            fontFamily: 'CustomFont',
                                            fontWeight: 400,
                                        }}
                                    />
                                ))}
                            </Box>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel sx={{
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20',
                                    fontWeight: 400
                                }}>Select Quality</InputLabel>
                                <Select labelId="quality-label"
                                    label="Select Quality"
                                    value={qualityId}
                                    onChange={(e) => saveQuality(e.target.value)}
                                    disabled={isCanUpdate !== 1}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont', // Your custom font here
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'

                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont', // Custom font for the label
                                            color: '#1D1B20'
                                        },
                                        '& .MuiSelect-select': {
                                            fontFamily: 'CustomFont', // Custom font for the selected value
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'
                                        },
                                    }}
                                >
                                    {qualityOptions.map(quality => (
                                        <MenuItem sx={{
                                            fontFamily: 'CustomFont',
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'
                                        }} key={quality.userId} value={quality.userId}>{quality.name} ({quality.userId})</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    )}

                    {isCanEdit ?
                        <Grid sx={{ textAlign: 'end' }}>
                            <Typography onClick={handleEdit} sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 400,
                                textTransform: 'capitalize',
                                fontSize: '18px',
                                cursor: 'pointer',
                                mt: 5,
                                display: 'inline-flex',
                                mr: 1
                            }}>
                                Edit
                            </Typography>
                        </Grid>
                        : ""
                    }
                </Box>
            </Box>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle
                    sx={{
                        fontFamily: 'CustomFont',
                        fontWeight: 500
                    }}
                >
                    Expert Selected
                </DialogTitle>
                <DialogContent>
                    {
                        currentExpert?.map((expert, index) => (
                            <>
                                <DialogContentText sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 500
                                }}>
                                    Number of words for <b>{`${expert?.name}`}</b>.
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="Number of Words"
                                    fullWidth
                                    value={expert?.numberOfWords}
                                    onChange={(e) => handleNumberOfWords(e, index)}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            fontFamily: 'CustomFont', // Your custom font here
                                            fontWeight: 500,
                                            fontSize: '17px',
                                            lineHeight: '28px',
                                            color: '#1D1B20'

                                        },
                                        '& .MuiInputLabel-root': {
                                            fontFamily: 'CustomFont', // Custom font for the label
                                            color: '#1D1B20',
                                            fontSize: '17px'
                                        },
                                    }}
                                />
                            </>
                        ))
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveExpert} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openPay} onClose={handleClose}>
                <DialogTitle sx={{ alignSelf: 'center', fontFamily: 'CustomFont', fontSize: '22px', fontWeight: 700 }}>Add Payment</DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', gap: '20px' }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="payment"
                            label="Payment"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={paymentAmount}
                            onChange={(e) => setPaymentAmount(e.target.value)}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontFamily: 'CustomFont', // Your custom font here
                                    fontWeight: 500,
                                    fontSize: '17px',
                                    lineHeight: '28px',
                                    color: '#1D1B20'

                                },
                                '& .MuiInputLabel-root': {
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20',
                                    fontSize: '17px'
                                },
                            }}
                        />
                        {/* <TextField
                            margin="dense"
                            id="mode-of-payment"
                            label="Mode of payment"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={modeOfPayment}
                            onChange={(e) => setModeOfPayment(e.target.value)}
                            sx={{
                                '& .MuiInputBase-input': {
                                    fontFamily: 'CustomFont', // Your custom font here
                                    fontWeight: 500,
                                    fontSize: '17px',
                                    lineHeight: '28px',
                                    color: '#1D1B20'

                                },
                                '& .MuiInputLabel-root': {
                                    fontFamily: 'CustomFont', // Custom font for the label
                                    color: '#1D1B20',
                                    fontSize: '17px'
                                },
                            }}
                        /> */}
                    </div>
                    <DialogActions sx={{ justifyContent: 'center', mt: 2 }}>
                        <Button onClick={handleSavePayment} sx={{
                            width: '186px', height: '42px', borderRadius: '10px', background: '#3C455E', '&:hover': {
                                background: '#3C455E'
                            },
                        }}>
                            <Typography sx={{
                                fontFamily: 'CustomFont',
                                fontWeight: 700,
                                textTransform: 'capitalize',
                                fontSize: '19px',
                                lineHeight: '24px',
                                color: 'white'

                            }}>
                                Save
                            </Typography>
                        </Button>
                    </DialogActions>
                    <Grid sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" sx={{
                            mt: 2, fontFamily: 'CustomFont',
                            fontWeight: 700,
                            fontSize: '22px',
                            lineHeight: '39px'
                        }}>
                            Payment Received - ₹{assignmentDetail.total_payment_received ? assignmentDetail.total_payment_received : "--"}
                        </Typography>


                        {payments && payments.map((payment, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
                                <Typography variant="body1" sx={{
                                    fontFamily: 'CustomFont',
                                    fontWeight: 500,
                                    fontSize: '19px',
                                    lineHeight: '24px'
                                }}>
                                    ₹{payment.amount} at {new Date(payment.createdAt).toLocaleString('en-IN', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}
                                    {' '}
                                    {/* through UPI */}
                                    {/* {payment.mode_of_payment} */}
                                </Typography>
                                <IconButton aria-label="delete" onClick={() => handleRemovePayment(payment.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        ))}

                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AssignmentDetails;
